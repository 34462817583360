import React from "react";
import { Bar } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const OrdersBarGraph = ({ orders }) => {
	// Get unique norms from orders
	const usedNorms = [...new Set(orders.map((order) => order.norm))];

	// Count orders per norm
	const normCounts = usedNorms.reduce((acc, norm) => {
		acc[norm] = orders.filter((order) => order.norm === norm).length;
		return acc;
	}, {});

	const data = {
		labels: usedNorms,
		datasets: [
			{
				label: "Nombre de commandes",
				data: usedNorms.map((norm) => normCounts[norm]),
				backgroundColor: "rgba(54, 162, 235, 0.5)",
				borderColor: "rgb(54, 162, 235)",
				borderWidth: 1,
			},
		],
	};

	const totalOrders = Object.values(normCounts).reduce(
		(sum, count) => sum + count,
		0
	);

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: { position: "top" },
			title: {
				display: true,
				text: `${totalOrders} commandes effectuées`,
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				ticks: { stepSize: 1 },
			},
		},
	};

	return <Bar data={data} options={options} />;
};

export default OrdersBarGraph;
